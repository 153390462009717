import { isAddress } from "@ethersproject/address"

import { ROUTES } from "common/constants/routes"
import { shortString } from "common/helpers/string"
import { getWhiteLabelRoute } from "whitelabel/utils/breadcrumb"

export type BreadcrumbItem = {
  label: string
  mobileLabel?: string
  path?: string
}

export type Breadcrumb = BreadcrumbItem[]

export const BREADCRUMB = {
  home: (): Breadcrumb => [{ label: "Home", path: ROUTES.home() }],
  governance: {
    id: (governanceSlug: string, governanceName: string): Breadcrumb => [
      { label: "Home", path: ROUTES.home() },
      {
        label: governanceName,
        path: ROUTES.governance.id(governanceSlug),
      },
    ],
    delegates: (
      governanceId: string,
      governanceName: string,
      isWhiteLabel: boolean,
    ): Breadcrumb => [
      { label: "Home", path: ROUTES.home() },
      {
        label: governanceName,
        path: isWhiteLabel
          ? getWhiteLabelRoute(ROUTES.governance.id(governanceId))
          : ROUTES.governance.id(governanceId),
      },
      {
        label: "Delegates",
        path: ROUTES.governance.delegates(governanceId),
      },
    ],
    forum: (
      governanceId: string,
      governanceName: string,
      isWhiteLabel: boolean,
    ): Breadcrumb => [
      { label: "Home", path: ROUTES.home() },
      {
        label: governanceName,
        path: isWhiteLabel
          ? getWhiteLabelRoute(ROUTES.governance.id(governanceId))
          : ROUTES.governance.id(governanceId),
      },
      {
        label: "Forum",
        path: isWhiteLabel
          ? getWhiteLabelRoute(ROUTES.governance.forum(governanceId))
          : ROUTES.governance.forum(governanceId),
      },
    ],
    guardians: (
      governanceId: string,
      governanceName: string,
      isWhiteLabel: boolean,
    ): Breadcrumb => [
      { label: "Home", path: ROUTES.home() },
      {
        label: governanceName,
        path: isWhiteLabel
          ? getWhiteLabelRoute(ROUTES.governance.id(governanceId))
          : ROUTES.governance.id(governanceId),
      },
      {
        label: "Guardians",
        path: ROUTES.governance.guardians(governanceId),
      },
    ],
    securityCouncilMembers: (
      governanceId: string,
      governanceName: string,
      isWhiteLabel: boolean,
    ): Breadcrumb => [
      { label: "Home", path: ROUTES.home() },
      {
        label: governanceName,
        path: isWhiteLabel
          ? getWhiteLabelRoute(ROUTES.governance.id(governanceId))
          : ROUTES.governance.id(governanceId),
      },
      {
        label: "Security Council",
        path: ROUTES.governance.securityCouncilMembers(governanceId),
      },
    ],
    draft: {
      index: (
        governanceSlug: string,
        governanceName: string,
        draftId: string,
      ): Breadcrumb => [
        { label: "Home", path: ROUTES.home() },
        {
          label: governanceName,
          path: ROUTES.governance.id(governanceSlug),
        },
        {
          label: "Proposals",
          path: ROUTES.governance.proposals.list(governanceSlug),
        },
        {
          label: "Draft Proposal",
          path: ROUTES.governance.draft.index(governanceSlug, draftId),
        },
      ],
      edit: (
        governanceSlug: string,
        governanceName: string,
        draftId: string,
      ): Breadcrumb => [
        { label: "Home", path: ROUTES.home() },
        {
          label: governanceName,
          path: ROUTES.governance.id(governanceSlug),
        },
        {
          label: "Proposals",
          path: ROUTES.governance.proposals.list(governanceSlug),
        },
        {
          label: "Draft Proposal",
          path: ROUTES.governance.draft.index(governanceSlug, draftId),
        },
        {
          label: "Edit",
          path: ROUTES.governance.draft.edit(governanceSlug, draftId),
        },
      ],
      history: (
        governanceSlug: string,
        governanceName: string,
        draftId: string,
      ): Breadcrumb => [
        { label: "Home", path: ROUTES.home() },
        {
          label: governanceName,
          path: ROUTES.governance.id(governanceSlug),
        },
        {
          label: "Proposals",
          path: ROUTES.governance.proposals.list(governanceSlug),
        },
        {
          label: "Draft Proposal",
          path: ROUTES.governance.draft.index(governanceSlug, draftId),
        },
        {
          label: "History",
          path: ROUTES.governance.draft.history(governanceSlug, draftId),
        },
      ],
    },
    proposal: {
      id: (
        governanceSlug: string,
        governanceName: string,
        proposalId: string,
        isWhiteLabel: boolean,
      ): Breadcrumb => [
        { label: "Home", path: ROUTES.home() },
        {
          label: governanceName,
          path: isWhiteLabel
            ? getWhiteLabelRoute(ROUTES.governance.id(governanceSlug))
            : ROUTES.governance.id(governanceSlug),
        },
        {
          label: "Proposals",
          path: isWhiteLabel
            ? getWhiteLabelRoute(
                ROUTES.governance.proposals.list(governanceSlug),
              )
            : ROUTES.governance.proposals.list(governanceSlug),
        },
        {
          label: "Proposal",
          path: ROUTES.governance.proposal.id(governanceSlug, proposalId, ""),
        },
      ],
      votes: (
        governanceSlug: string,
        governanceName: string,
        proposalId: string,
        govId: string,
        isWhiteLabel: boolean,
      ): Breadcrumb => [
        { label: "Home", path: ROUTES.home() },
        {
          label: governanceName,
          path: isWhiteLabel
            ? getWhiteLabelRoute(ROUTES.governance.id(governanceSlug))
            : ROUTES.governance.id(governanceSlug),
        },
        {
          label: "Proposals",
          path: isWhiteLabel
            ? getWhiteLabelRoute(
                ROUTES.governance.proposals.list(governanceSlug),
              )
            : ROUTES.governance.proposals.list(governanceSlug),
        },
        {
          label: "Proposal",
          path: isWhiteLabel
            ? getWhiteLabelRoute(
                ROUTES.governance.proposal.id(
                  governanceSlug,
                  proposalId,
                  govId ?? "",
                ),
              )
            : ROUTES.governance.proposal.id(
                governanceSlug,
                proposalId,
                govId ?? "",
              ),
        },
        {
          label: "Votes",
          path: ROUTES.governance.proposal.votes(
            governanceSlug,
            proposalId,
            govId ?? "",
          ),
        },
      ],
    },
    drafts: {
      list: (governanceSlug: string, governanceName: string): Breadcrumb => [
        { label: "Home", path: ROUTES.home() },
        {
          label: governanceName,
          path: ROUTES.governance.id(governanceSlug),
        },
        {
          label: "My Drafts",
          path: ROUTES.governance.drafts.list(governanceSlug),
        },
      ],
    },
    proposals: {
      list: (
        governanceSlug: string,
        governanceName: string,
        isWhiteLabel: boolean,
      ): Breadcrumb => [
        { label: "Home", path: ROUTES.home() },
        {
          label: governanceName,
          path: isWhiteLabel
            ? getWhiteLabelRoute(ROUTES.governance.id(governanceSlug))
            : ROUTES.governance.id(governanceSlug),
        },
        {
          label: "Proposals",
          path: ROUTES.governance.proposals.list(governanceSlug),
        },
      ],
    },
    create: (
      governanceSlug: string,
      governanceName: string,
      isWhiteLabel: boolean,
    ): Breadcrumb => [
      { label: "Home", path: ROUTES.home() },
      {
        label: governanceName,
        path: isWhiteLabel
          ? getWhiteLabelRoute(ROUTES.governance.id(governanceSlug))
          : ROUTES.governance.id(governanceSlug),
      },
      {
        label: "Create Proposal",
        path: ROUTES.governance.proposal.create(governanceSlug),
      },
    ],
    voters: (governanceSlug: string, governanceName: string): Breadcrumb => [
      { label: "Home", path: ROUTES.home() },
      {
        label: governanceName,
        path: ROUTES.governance.id(governanceSlug),
      },
      {
        label: "Voters",
        path: ROUTES.governance.voters(governanceSlug),
      },
    ],
    treasury: (governanceSlug: string, governanceName: string): Breadcrumb => [
      { label: "Home", path: ROUTES.home() },
      {
        label: governanceName,
        path: ROUTES.governance.id(governanceSlug),
      },
      {
        label: "Treasury Assets",
        path: ROUTES.governance.treasury.list(governanceSlug),
      },
    ],
    welcome: (governanceSlug: string, governanceName: string): Breadcrumb => [
      { label: "Home", path: ROUTES.home() },
      {
        label: governanceName,
        path: ROUTES.governance.id(governanceSlug),
      },
      {
        label: "Welcome",
        path: ROUTES.governance.welcome(governanceSlug),
      },
    ],
    council: {
      index: (
        governanceSlug: string,
        governanceName: string,
        councilSlug: string,
        councilName: string,
        isWhiteLabel: boolean,
      ): Breadcrumb => [
        { label: "Home", path: ROUTES.home() },
        {
          label: governanceName,
          path: isWhiteLabel
            ? getWhiteLabelRoute(ROUTES.governance.id(governanceSlug))
            : ROUTES.governance.id(governanceSlug),
        },
        {
          label: councilName,
          path: isWhiteLabel
            ? getWhiteLabelRoute(
                ROUTES.governance.council.index(governanceSlug, councilSlug),
              )
            : ROUTES.governance.council.index(governanceSlug, councilSlug),
        },
      ],

      election: {
        index: (
          governanceSlug: string,
          governanceName: string,
          councilSlug: string,
          councilName: string,
          electionNumber: number,
          isWhiteLabel: boolean,
        ): Breadcrumb => [
          { label: "Home", path: ROUTES.home() },
          {
            label: governanceName,
            path: isWhiteLabel
              ? getWhiteLabelRoute(ROUTES.governance.id(governanceSlug))
              : ROUTES.governance.id(governanceSlug),
          },
          {
            label: councilName,
            path: isWhiteLabel
              ? getWhiteLabelRoute(
                  ROUTES.governance.council.index(governanceSlug, councilSlug),
                )
              : ROUTES.governance.council.index(governanceSlug, councilSlug),
          },
          {
            label: "Election",
            path: isWhiteLabel
              ? getWhiteLabelRoute(
                  ROUTES.governance.council.election.index(
                    governanceSlug,
                    councilSlug,
                    String(electionNumber),
                  ),
                )
              : ROUTES.governance.council.election.index(
                  governanceSlug,
                  councilSlug,
                  String(electionNumber),
                ),
          },
        ],

        register: {
          index: (
            governanceSlug: string,
            governanceName: string,
            councilSlug: string,
            councilName: string,
            electionNumber: number,
            isWhiteLabel: boolean,
          ): Breadcrumb => [
            { label: "Home", path: ROUTES.home() },
            {
              label: governanceName,
              path: isWhiteLabel
                ? getWhiteLabelRoute(ROUTES.governance.id(governanceSlug))
                : ROUTES.governance.id(governanceSlug),
            },
            {
              label: councilName,
              path: isWhiteLabel
                ? getWhiteLabelRoute(
                    ROUTES.governance.council.index(
                      governanceSlug,
                      councilSlug,
                    ),
                  )
                : ROUTES.governance.council.index(governanceSlug, councilSlug),
            },
            {
              label: "Election",
              path: isWhiteLabel
                ? getWhiteLabelRoute(
                    ROUTES.governance.council.election.index(
                      governanceSlug,
                      councilSlug,
                      String(electionNumber),
                    ),
                  )
                : ROUTES.governance.council.election.index(
                    governanceSlug,
                    councilSlug,
                    String(electionNumber),
                  ),
            },
            {
              label: "Register",
              path: isWhiteLabel
                ? getWhiteLabelRoute(
                    ROUTES.governance.council.election.register(
                      governanceSlug,
                      councilSlug,
                      String(electionNumber),
                    ),
                  )
                : ROUTES.governance.council.election.register(
                    governanceSlug,
                    councilSlug,
                    String(electionNumber),
                  ),
            },
          ],
          edit: (
            governanceSlug: string,
            governanceName: string,
            councilSlug: string,
            councilName: string,
            electionNumber: number,
            isWhiteLabel: boolean,
          ): Breadcrumb => [
            { label: "Home", path: ROUTES.home() },
            {
              label: governanceName,
              path: isWhiteLabel
                ? getWhiteLabelRoute(ROUTES.governance.id(governanceSlug))
                : ROUTES.governance.id(governanceSlug),
            },
            {
              label: councilName,
              path: isWhiteLabel
                ? getWhiteLabelRoute(
                    ROUTES.governance.council.index(
                      governanceSlug,
                      councilSlug,
                    ),
                  )
                : ROUTES.governance.council.index(governanceSlug, councilSlug),
            },
            {
              label: "Election",
              path: isWhiteLabel
                ? getWhiteLabelRoute(
                    ROUTES.governance.council.election.index(
                      governanceSlug,
                      councilSlug,
                      String(electionNumber),
                    ),
                  )
                : ROUTES.governance.council.election.index(
                    governanceSlug,
                    councilSlug,
                    String(electionNumber),
                  ),
            },
            {
              label: "Edit Election Profile",
              path: isWhiteLabel
                ? getWhiteLabelRoute(
                    ROUTES.governance.council.election.edit(
                      governanceSlug,
                      councilSlug,
                      String(electionNumber),
                    ),
                  )
                : ROUTES.governance.council.election.edit(
                    governanceSlug,
                    councilSlug,
                    String(electionNumber),
                  ),
            },
          ],
        },

        round1: {
          index: (
            governanceSlug: string,
            governanceName: string,
            councilSlug: string,
            councilName: string,
            electionNumber: number,
            isWhiteLabel: boolean,
          ): Breadcrumb => [
            { label: "Home", path: ROUTES.home() },
            {
              label: governanceName,
              path: isWhiteLabel
                ? getWhiteLabelRoute(ROUTES.governance.id(governanceSlug))
                : ROUTES.governance.id(governanceSlug),
            },
            {
              label: councilName,
              path: isWhiteLabel
                ? getWhiteLabelRoute(
                    ROUTES.governance.council.index(
                      governanceSlug,
                      councilSlug,
                    ),
                  )
                : ROUTES.governance.council.index(governanceSlug, councilSlug),
            },
            {
              label: "Election Overview",
              path: isWhiteLabel
                ? getWhiteLabelRoute(
                    ROUTES.governance.council.election.index(
                      governanceSlug,
                      councilSlug,
                      String(electionNumber),
                    ),
                  )
                : ROUTES.governance.council.election.index(
                    governanceSlug,
                    councilSlug,
                    String(electionNumber),
                  ),
            },
            {
              label: "Nominee Selection",
              path: isWhiteLabel
                ? getWhiteLabelRoute(
                    ROUTES.governance.council.election.round1.index(
                      governanceSlug,
                      councilSlug,
                      String(electionNumber),
                    ),
                  )
                : ROUTES.governance.council.election.round1.index(
                    governanceSlug,
                    councilSlug,
                    String(electionNumber),
                  ),
            },
          ],
          pending: (
            governanceSlug: string,
            governanceName: string,
            councilSlug: string,
            councilName: string,
            electionNumber: number,
            isWhiteLabel: boolean,
          ): Breadcrumb => [
            { label: "Home", path: ROUTES.home() },
            {
              label: governanceName,
              path: isWhiteLabel
                ? getWhiteLabelRoute(ROUTES.governance.id(governanceSlug))
                : ROUTES.governance.id(governanceSlug),
            },
            {
              label: councilName,
              path: isWhiteLabel
                ? getWhiteLabelRoute(
                    ROUTES.governance.council.index(
                      governanceSlug,
                      councilSlug,
                    ),
                  )
                : ROUTES.governance.council.index(governanceSlug, councilSlug),
            },
            {
              label: "Election Overview",
              path: isWhiteLabel
                ? getWhiteLabelRoute(
                    ROUTES.governance.council.election.index(
                      governanceSlug,
                      councilSlug,
                      String(electionNumber),
                    ),
                  )
                : ROUTES.governance.council.election.index(
                    governanceSlug,
                    councilSlug,
                    String(electionNumber),
                  ),
            },
            {
              label: "Registered candidates",
              path: isWhiteLabel
                ? getWhiteLabelRoute(
                    ROUTES.governance.council.election.round1.index(
                      governanceSlug,
                      councilSlug,
                      String(electionNumber),
                    ),
                  )
                : ROUTES.governance.council.election.round1.index(
                    governanceSlug,
                    councilSlug,
                    String(electionNumber),
                  ),
            },
          ],
          candidate: (
            governanceSlug: string,
            governanceName: string,
            councilSlug: string,
            councilName: string,
            electionNumber: number,
            candidateAddress: string,
            isWhiteLabel: boolean,
          ): Breadcrumb => [
            { label: "Home", path: ROUTES.home() },
            {
              label: governanceName,
              path: isWhiteLabel
                ? getWhiteLabelRoute(ROUTES.governance.id(governanceSlug))
                : ROUTES.governance.id(governanceSlug),
            },
            {
              label: councilName,
              path: isWhiteLabel
                ? getWhiteLabelRoute(
                    ROUTES.governance.council.index(
                      governanceSlug,
                      councilSlug,
                    ),
                  )
                : ROUTES.governance.council.index(governanceSlug, councilSlug),
            },
            {
              label: "Election",
              path: isWhiteLabel
                ? getWhiteLabelRoute(
                    ROUTES.governance.council.election.index(
                      governanceSlug,
                      councilSlug,
                      String(electionNumber),
                    ),
                  )
                : ROUTES.governance.council.election.index(
                    governanceSlug,
                    councilSlug,
                    String(electionNumber),
                  ),
            },
            {
              label: "Nominee Selection",
              path: isWhiteLabel
                ? getWhiteLabelRoute(
                    ROUTES.governance.council.election.round1.index(
                      governanceSlug,
                      councilSlug,
                      String(electionNumber),
                    ),
                  )
                : ROUTES.governance.council.election.round1.index(
                    governanceSlug,
                    councilSlug,
                    String(electionNumber),
                  ),
            },
            {
              label: "Candidate",
              path: isWhiteLabel
                ? getWhiteLabelRoute(
                    ROUTES.governance.council.election.round1.candidate(
                      governanceSlug,
                      councilSlug,
                      String(electionNumber),
                      candidateAddress,
                    ),
                  )
                : ROUTES.governance.council.election.round1.candidate(
                    governanceSlug,
                    councilSlug,
                    String(electionNumber),
                    candidateAddress,
                  ),
            },
          ],
        },

        round2: {
          index: (
            governanceSlug: string,
            governanceName: string,
            councilSlug: string,
            councilName: string,
            electionNumber: number,
            isWhiteLabel: boolean,
          ): Breadcrumb => [
            { label: "Home", path: ROUTES.home() },
            {
              label: governanceName,
              path: isWhiteLabel
                ? getWhiteLabelRoute(ROUTES.governance.id(governanceSlug))
                : ROUTES.governance.id(governanceSlug),
            },
            {
              label: councilName,
              path: isWhiteLabel
                ? getWhiteLabelRoute(
                    ROUTES.governance.council.index(
                      governanceSlug,
                      councilSlug,
                    ),
                  )
                : ROUTES.governance.council.index(governanceSlug, councilSlug),
            },
            {
              label: "Election Overview",
              path: isWhiteLabel
                ? getWhiteLabelRoute(
                    ROUTES.governance.council.election.index(
                      governanceSlug,
                      councilSlug,
                      String(electionNumber),
                    ),
                  )
                : ROUTES.governance.council.election.index(
                    governanceSlug,
                    councilSlug,
                    String(electionNumber),
                  ),
            },
            {
              label: "Member Election",
              path: isWhiteLabel
                ? getWhiteLabelRoute(
                    ROUTES.governance.council.election.round2.index(
                      governanceSlug,
                      councilSlug,
                      String(electionNumber),
                    ),
                  )
                : ROUTES.governance.council.election.round2.index(
                    governanceSlug,
                    councilSlug,
                    String(electionNumber),
                  ),
            },
          ],
          nominee: (
            governanceSlug: string,
            governanceName: string,
            councilSlug: string,
            councilName: string,
            electionNumber: number,
            nomineeAddress: string,
            isWhiteLabel: boolean,
          ): Breadcrumb => [
            { label: "Home", path: ROUTES.home() },
            {
              label: governanceName,
              path: isWhiteLabel
                ? getWhiteLabelRoute(ROUTES.governance.id(governanceSlug))
                : ROUTES.governance.id(governanceSlug),
            },
            {
              label: councilName,
              path: isWhiteLabel
                ? getWhiteLabelRoute(
                    ROUTES.governance.council.index(
                      governanceSlug,
                      councilSlug,
                    ),
                  )
                : ROUTES.governance.council.index(governanceSlug, councilSlug),
            },
            {
              label: "Election",
              path: isWhiteLabel
                ? getWhiteLabelRoute(
                    ROUTES.governance.council.election.index(
                      governanceSlug,
                      councilSlug,
                      String(electionNumber),
                    ),
                  )
                : ROUTES.governance.council.election.index(
                    governanceSlug,
                    councilSlug,
                    String(electionNumber),
                  ),
            },
            {
              label: "Member Election",
              path: isWhiteLabel
                ? getWhiteLabelRoute(
                    ROUTES.governance.council.election.round2.index(
                      governanceSlug,
                      councilSlug,
                      String(electionNumber),
                    ),
                  )
                : ROUTES.governance.council.election.round2.index(
                    governanceSlug,
                    councilSlug,
                    String(electionNumber),
                  ),
            },
            {
              label: "Nominee",
              path: isWhiteLabel
                ? getWhiteLabelRoute(
                    ROUTES.governance.council.election.round2.nominee(
                      governanceSlug,
                      councilSlug,
                      String(electionNumber),
                      nomineeAddress,
                    ),
                  )
                : ROUTES.governance.council.election.round2.nominee(
                    governanceSlug,
                    councilSlug,
                    String(electionNumber),
                    nomineeAddress,
                  ),
            },
          ],
        },
      },
    },
    token: {
      wrap: (
        governanceSlug: string,
        governanceName: string,
        isWhiteLabel: boolean,
      ): Breadcrumb => [
        { label: "Home", path: ROUTES.home() },
        {
          label: governanceName,
          path: isWhiteLabel
            ? getWhiteLabelRoute(ROUTES.governance.id(governanceSlug))
            : ROUTES.governance.id(governanceSlug),
        },
        {
          label: "My voting power",
          path: ROUTES.governance.myVotingPower.index(governanceSlug),
        },
        {
          label: "Wrap",
          path: isWhiteLabel
            ? getWhiteLabelRoute(ROUTES.governance.token.wrap(governanceSlug))
            : ROUTES.governance.token.wrap(governanceSlug),
        },
      ],
      unwrap: (
        governanceSlug: string,
        governanceName: string,
        isWhiteLabel: boolean,
      ): Breadcrumb => [
        { label: "Home", path: ROUTES.home() },
        {
          label: governanceName,
          path: isWhiteLabel
            ? getWhiteLabelRoute(ROUTES.governance.id(governanceSlug))
            : ROUTES.governance.id(governanceSlug),
        },
        {
          label: "My voting power",
          path: ROUTES.governance.myVotingPower.index(governanceSlug),
        },
        {
          label: "Unwrap",
          path: isWhiteLabel
            ? getWhiteLabelRoute(ROUTES.governance.token.unwrap(governanceSlug))
            : ROUTES.governance.token.unwrap(governanceSlug),
        },
      ],
    },
    delegateProfile: (
      governanceSlug: string,
      governanceName: string,
      address: string,
      isWhiteLabel: boolean,
      withGovernor = false,
    ): Breadcrumb => {
      if (withGovernor) {
        return [
          {
            label: "Home",
            path: isWhiteLabel
              ? getWhiteLabelRoute(ROUTES.governance.id(governanceSlug))
              : ROUTES.home(),
          },
          {
            label: governanceName,
            path: ROUTES.governance.id(governanceSlug),
          },
          {
            label: "My voting power",
            path: ROUTES.governance.myVotingPower.index(governanceSlug),
          },
          {
            label: "Delegate Profile",
            path: ROUTES.governance.delegate.index(governanceSlug),
          },
        ]
      }

      return [
        {
          label: "Home",
          path: isWhiteLabel
            ? getWhiteLabelRoute(ROUTES.governance.id(governanceSlug))
            : ROUTES.home(),
        },
        {
          label: governanceName,
          path: ROUTES.governance.id(governanceSlug),
        },
        {
          label: "Profile",
          path: ROUTES.governance.delegate.profile(governanceSlug, address),
        },
        {
          label: "Delegate Profile",
          path: ROUTES.governance.delegate.index(governanceSlug),
        },
      ]
    },
    delegateProfileEdit: (
      governanceSlug: string,
      governanceName: string,
      address: string,
      isWhiteLabel: boolean,
      withGovernor = false,
    ): Breadcrumb => {
      if (withGovernor) {
        return [
          {
            label: "Home",
            path: isWhiteLabel
              ? getWhiteLabelRoute(ROUTES.governance.id(governanceSlug))
              : ROUTES.home(),
          },
          {
            label: governanceName,
            path: ROUTES.governance.id(governanceSlug),
          },
          {
            label: "My voting power",
            path: ROUTES.governance.myVotingPower.index(governanceSlug),
          },
          {
            label: "Delegate Profile",
            path: ROUTES.governance.delegate.index(governanceSlug),
          },
          {
            label: "Edit",
            path: ROUTES.governance.delegate.edit(governanceSlug),
          },
        ]
      }

      return [
        {
          label: "Home",
          path: isWhiteLabel
            ? getWhiteLabelRoute(ROUTES.governance.id(governanceSlug))
            : ROUTES.home(),
        },
        {
          label: governanceName,
          path: ROUTES.governance.id(governanceSlug),
        },
        {
          label: "Profile",
          path: ROUTES.governance.delegate.profile(governanceSlug, address),
        },
        {
          label: "Delegate Profile",
          path: ROUTES.governance.delegate.index(governanceSlug),
        },
        {
          label: "Edit",
          path: ROUTES.governance.delegate.edit(governanceSlug),
        },
      ]
    },
    myVotingPower: {
      index: (
        governanceSlug: string,
        governanceName: string,
        isWhiteLabel?: boolean,
      ): Breadcrumb => [
        isWhiteLabel
          ? { label: "Home", path: ROUTES.governance.id(governanceSlug) }
          : { label: "Home", path: ROUTES.home() },
        {
          label: governanceName,
          path: ROUTES.governance.id(governanceSlug),
        },
        {
          label: "My voting power",
          path: ROUTES.governance.myVotingPower.index(governanceSlug),
        },
      ],
      received: (
        governanceSlug: string,
        governanceName: string,
        isWhiteLabel?: boolean,
      ): Breadcrumb => [
        isWhiteLabel
          ? { label: "Home", path: ROUTES.governance.id(governanceSlug) }
          : { label: "Home", path: ROUTES.home() },
        {
          label: governanceName,
          path: ROUTES.governance.id(governanceSlug),
        },
        {
          label: "My voting power",
          path: ROUTES.governance.myVotingPower.index(governanceSlug),
        },
      ],
    },
    claim: {
      home: (
        governanceSlug: string,
        governanceName: string,
        isWhiteLabel = false,
      ): Breadcrumb => [
        { label: "Home", path: ROUTES.home() },
        {
          label: governanceName,
          path: isWhiteLabel
            ? getWhiteLabelRoute(ROUTES.governance.id(governanceSlug))
            : ROUTES.governance.id(governanceSlug),
        },
        {
          label: "Claim",
          path: isWhiteLabel
            ? getWhiteLabelRoute(ROUTES.governance.claim.home(governanceSlug))
            : ROUTES.governance.claim.home(governanceSlug),
        },
      ],
      start: (
        governanceSlug: string,
        governanceName: string,
        isWhiteLabel = false,
      ): Breadcrumb => [
        { label: "Home", path: ROUTES.home() },
        {
          label: governanceName,
          path: isWhiteLabel
            ? getWhiteLabelRoute(ROUTES.governance.id(governanceSlug))
            : ROUTES.governance.id(governanceSlug),
        },
        {
          label: "Claim",
          path: isWhiteLabel
            ? getWhiteLabelRoute(ROUTES.governance.claim.home(governanceSlug))
            : ROUTES.governance.claim.home(governanceSlug),
        },
        {
          label: "Delegate & Claim",
          path: isWhiteLabel
            ? getWhiteLabelRoute(ROUTES.governance.claim.start(governanceSlug))
            : ROUTES.governance.claim.start(governanceSlug),
        },
      ],
    },
    registerAsDelegate: (
      governanceSlug: string,
      governanceName: string,
      isWhiteLabel = false,
    ): Breadcrumb => [
      { label: "Home", path: ROUTES.home() },
      {
        label: governanceName,
        path: isWhiteLabel
          ? getWhiteLabelRoute(ROUTES.governance.id(governanceSlug))
          : ROUTES.governance.id(governanceSlug),
      },
      {
        label: "Delegates",
        path: isWhiteLabel
          ? getWhiteLabelRoute(ROUTES.governance.delegates(governanceSlug))
          : ROUTES.governance.delegates(governanceSlug),
      },
      {
        label: "Register as a delegate",
        path: isWhiteLabel
          ? getWhiteLabelRoute(
              ROUTES.governance.delegate.register(governanceSlug),
            )
          : ROUTES.governance.delegate.register(governanceSlug),
      },
    ],
    selectDelegate: (
      organizationSlug: string,
      organizationName: string,
      isWhiteLabel = false,
    ) => [
      { label: "Home", path: ROUTES.home() },
      {
        label: organizationName,
        path: isWhiteLabel
          ? getWhiteLabelRoute(ROUTES.governance.id(organizationSlug))
          : ROUTES.governance.id(organizationSlug),
      },
      {
        label: "Delegates",
        path: isWhiteLabel
          ? getWhiteLabelRoute(ROUTES.governance.delegates(organizationSlug))
          : ROUTES.governance.delegates(organizationSlug),
      },
      {
        label: "Stake for Governance",
        path: isWhiteLabel
          ? getWhiteLabelRoute(
              ROUTES.governance.delegate.register(organizationSlug),
            )
          : ROUTES.governance.delegate.register(organizationSlug),
      },
    ],
    faq: (
      governanceSlug: string,
      governanceName: string,
      isWhiteLabel = false,
    ): Breadcrumb => [
      { label: "Home", path: ROUTES.home() },
      {
        label: governanceName,
        path: isWhiteLabel
          ? getWhiteLabelRoute(ROUTES.governance.id(governanceSlug))
          : ROUTES.governance.id(governanceSlug),
      },
      {
        label: "FAQs",
        path: isWhiteLabel
          ? getWhiteLabelRoute(ROUTES.governance.faq(governanceSlug))
          : ROUTES.governance.faq(governanceSlug),
      },
    ],
    stake: (
      governanceSlug: string,
      governanceName: string,
      isWhiteLabel = false,
    ): Breadcrumb => [
      { label: "Home", path: ROUTES.home() },
      {
        label: governanceName,
        path: isWhiteLabel
          ? getWhiteLabelRoute(ROUTES.governance.id(governanceSlug))
          : ROUTES.governance.id(governanceSlug),
      },
      {
        label: "Stake",
        path: isWhiteLabel
          ? getWhiteLabelRoute(ROUTES.governance.stake.index(governanceSlug))
          : ROUTES.governance.stake.index(governanceSlug),
      },
    ],
    stakeDeposit: (
      governanceSlug: string,
      governanceName: string,
      isWhiteLabel = false,
    ): Breadcrumb => [
      { label: "Home", path: ROUTES.home() },
      {
        label: governanceName,
        path: isWhiteLabel
          ? getWhiteLabelRoute(ROUTES.governance.id(governanceSlug))
          : ROUTES.governance.id(governanceSlug),
      },
      {
        label: "Stake",
        path: isWhiteLabel
          ? getWhiteLabelRoute(ROUTES.governance.stake.index(governanceSlug))
          : ROUTES.governance.stake.index(governanceSlug),
      },
      {
        label: "New stake position",
        path: isWhiteLabel
          ? getWhiteLabelRoute(ROUTES.governance.stake.deposit(governanceSlug))
          : ROUTES.governance.stake.deposit(governanceSlug),
      },
    ],
    stakeUpdate: (
      governanceSlug: string,
      governanceName: string,
      isWhiteLabel = false,
    ): Breadcrumb => [
      { label: "Home", path: ROUTES.home() },
      {
        label: governanceName,
        path: isWhiteLabel
          ? getWhiteLabelRoute(ROUTES.governance.id(governanceSlug))
          : ROUTES.governance.id(governanceSlug),
      },
      {
        label: "Stake",
        path: isWhiteLabel
          ? getWhiteLabelRoute(ROUTES.governance.stake.index(governanceSlug))
          : ROUTES.governance.stake.index(governanceSlug),
      },
      {
        label: "Update stake position",
        path: isWhiteLabel
          ? getWhiteLabelRoute(ROUTES.governance.stake.deposit(governanceSlug))
          : ROUTES.governance.stake.deposit(governanceSlug),
      },
    ],
    settings: {
      index: (governanceSlug: string, governanceName: string): Breadcrumb => [
        { label: "Home", path: ROUTES.home() },
        {
          label: governanceName,
          path: ROUTES.governance.id(governanceSlug),
        },
        {
          label: "Settings",
          path: ROUTES.governance.settings.index(governanceSlug),
        },
      ],
      basics: (governanceSlug: string, governanceName: string): Breadcrumb => [
        { label: "Home", path: ROUTES.home() },
        {
          label: governanceName,
          path: ROUTES.governance.id(governanceSlug),
        },
        {
          label: "Settings",
          path: ROUTES.governance.settings.index(governanceSlug),
        },
        {
          label: "Basics",
          path: ROUTES.governance.settings.basics(governanceSlug),
        },
      ],
      admins: (governanceSlug: string, governanceName: string): Breadcrumb => [
        { label: "Home", path: ROUTES.home() },
        {
          label: governanceName,
          path: ROUTES.governance.id(governanceSlug),
        },
        {
          label: "Settings",
          path: ROUTES.governance.settings.index(governanceSlug),
        },
        {
          label: "Admins",
          path: ROUTES.governance.settings.admins(governanceSlug),
        },
      ],
      voting: (governanceSlug: string, governanceName: string): Breadcrumb => [
        { label: "Home", path: ROUTES.home() },
        {
          label: governanceName,
          path: ROUTES.governance.id(governanceSlug),
        },
        {
          label: "Settings",
          path: ROUTES.governance.settings.index(governanceSlug),
        },
        {
          label: "Voting",
          path: ROUTES.governance.settings.voting(governanceSlug),
        },
      ],
      safes: (governanceSlug: string, governanceName: string): Breadcrumb => [
        { label: "Home", path: ROUTES.home() },
        {
          label: governanceName,
          path: ROUTES.governance.id(governanceSlug),
        },
        {
          label: "Settings",
          path: ROUTES.governance.settings.index(governanceSlug),
        },
        {
          label: "Safes",
          path: ROUTES.governance.settings.admins(governanceSlug),
        },
      ],
    },
  },
  org: {
    id: (organizationId: string, organizationName: string): Breadcrumb => [
      { label: "Home", path: ROUTES.home() },
      {
        label: organizationName,
        path: ROUTES.organization.id(organizationId),
      },
    ],
    linkGovernor: (
      organizationId: string,
      organizationName: string,
    ): Breadcrumb => [
      { label: "Home", path: ROUTES.home() },
      {
        label: organizationName,
        path: ROUTES.organization.id(organizationId),
      },
      {
        label: "Link Governor",
        path: ROUTES.organization.addGovernor(organizationId),
      },
    ],
    createSafe: (
      organizationId: string,
      organizationName: string,
    ): Breadcrumb => [
      { label: "Home", path: ROUTES.home() },
      {
        label: organizationName,
        path: ROUTES.organization.id(organizationId),
      },
      {
        label: "Create Safe",
        path: ROUTES.organization.createSafe(organizationId),
      },
    ],
    addSafe: (organizationId: string, organizationName: string): Breadcrumb => [
      { label: "Home", path: ROUTES.home() },
      {
        label: organizationName,
        path: ROUTES.organization.id(organizationId),
      },
      {
        label: "Add Safe",
        path: ROUTES.organization.addSafe(organizationId),
      },
    ],
  },
  profile: {
    index: (address: string, isWhiteLabel = false): Breadcrumb => [
      {
        label: "Home",
        path: isWhiteLabel
          ? getWhiteLabelRoute(ROUTES.dao.index())
          : ROUTES.home(),
      },
      {
        label: "Profile",
        path: ROUTES.profile(address),
      },
      {
        label: "All DAOs",
        path: ROUTES.profile(address),
      },
    ],
    withGovernance: (
      address: string,
      voterAddress: string,
      governanceId: string,
      governanceName: string,
      isWhiteLabel?: boolean,
    ): Breadcrumb => [
      isWhiteLabel
        ? {
            label: "Home",
            path: getWhiteLabelRoute(ROUTES.governance.id(governanceId)),
          }
        : { label: "Home", path: ROUTES.home() },
      {
        label: governanceName,
        path: isWhiteLabel
          ? getWhiteLabelRoute(ROUTES.governance.id(governanceId))
          : ROUTES.governance.id(governanceId),
      },
      {
        label: "Delegates",
        path: isWhiteLabel
          ? getWhiteLabelRoute(ROUTES.governance.delegates(governanceId))
          : ROUTES.governance.delegates(governanceId),
      },
      {
        label: voterAddress,
        path: ROUTES.profile(address),
      },
    ],
  },
  safe: {
    index: (
      safeId: string,
      safeLabel: string,
      isWhiteLabel: boolean,
    ): Breadcrumb => [
      isWhiteLabel
        ? {
            label: "Home",
            path: getWhiteLabelRoute(ROUTES.governance.id(safeId)),
          }
        : { label: "Home", path: ROUTES.home() },
      {
        label: safeLabel,
        mobileLabel: isAddress(safeLabel) ? shortString(safeLabel) : safeLabel,
        path: ROUTES.safe.id(safeId),
      },
    ],
    indexWithOrganization: (safeId: string, safeLabel: string): Breadcrumb => [
      { label: "Home", path: ROUTES.home() },
      {
        label: safeLabel,
        mobileLabel: isAddress(safeLabel) ? shortString(safeLabel) : safeLabel,
        path: ROUTES.safe.id(safeId),
      },
    ],
    edit: (safeId: string, safeLabel: string): Breadcrumb => [
      { label: "Home", path: ROUTES.home() },
      {
        label: safeLabel,
        mobileLabel: isAddress(safeLabel) ? shortString(safeLabel) : safeLabel,
        path: ROUTES.safe.id(safeId),
      },
      {
        label: "Edit",
        path: ROUTES.safe.edit(safeId),
      },
    ],
  },
  user: {
    settings: (address: string): Breadcrumb => [
      { label: "Home", path: ROUTES.home() },
      {
        label: "Profile",
        path: ROUTES.profile(address),
      },
      {
        label: "Settings",
        path: ROUTES.user.settings(),
      },
    ],
    connect: (): Breadcrumb => [
      { label: "Home", path: ROUTES.home() },
      {
        label: "Connect Account",
        path: ROUTES.user.connect(),
      },
    ],
    welcome: (): Breadcrumb => [
      { label: "Home", path: ROUTES.home() },
      {
        label: "Welcome",
        path: ROUTES.user.welcome(),
      },
    ],
  },
}
